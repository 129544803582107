// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-covid-safety-js": () => import("./../../../src/pages/covid-safety.js" /* webpackChunkName: "component---src-pages-covid-safety-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-financing-js": () => import("./../../../src/pages/insurance-financing.js" /* webpackChunkName: "component---src-pages-insurance-financing-js" */),
  "component---src-pages-new-patients-js": () => import("./../../../src/pages/new-patients.js" /* webpackChunkName: "component---src-pages-new-patients-js" */),
  "component---src-pages-our-doctors-js": () => import("./../../../src/pages/our-doctors.js" /* webpackChunkName: "component---src-pages-our-doctors-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

